<template>
  <div class="vuse-content-wrapper job-bank">
    <v-row no-gutters justify="space-between" align="center">
      <smart-breadcrumbs :items="navigationElements" />
      <help class="help--breadcrumb" :helpId="32" />
    </v-row>
    <!-- REQUESTS SECTION -->
    <v-card elevation="2" class="mt-1 neu-glow-inset">
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title class="text-h6">Bolsa de empleo</v-toolbar-title>
        <v-spacer />
        <!-- <v-select
          dense
          flat
          hide-details
          :items="types"
          v-model="selectedType"
          item-text="name"
          return-object
          class="ml-2"
          style="max-width: 200px"
        /> -->
        <v-btn icon @click="showQR = !showQR">
          <v-icon size="30">mdi-qrcode-scan</v-icon>
        </v-btn>
      </v-toolbar>
      <v-row class="mx-0 mt-md-4 mb-md-4 stats">
        <!-- STATUS STATS -->
        <v-col cols="12" sm="6" md="4" class="d-flex py-0 px-1 mt-2 mt-md-0">
          <v-card elevation="3" class="d-flex flex-column" style="width: 100%">
            <v-card-actions class="pa-1 justify-center"> Oferta laboral </v-card-actions>
            <v-divider />
            <v-row no-gutters align="center" justify="center">
              <v-chip
                :color="statusOptions.find((status) => status.id === 1).color"
                text-color="white"
              >
                <v-chip pill small class="grey darken-4" text-color="white">
                  <v-progress-circular
                    width="2"
                    indeterminate
                    size="15"
                    v-if="loadingStats"
                  />
                  <span v-else>
                    {{ stats.positionOffersActive ? stats.positionOffersActive : 0 }}
                  </span>
                </v-chip>
                Activas
              </v-chip>
              <v-chip
                :color="statusOptions.find((status) => status.id === 0).color"
                text-color="white"
              >
                <v-chip small class="grey darken-4" text-color="white">
                  <v-progress-circular
                    width="2"
                    indeterminate
                    size="15"
                    v-if="loadingStats"
                  />
                  <span v-else>
                    {{ stats.positionOffersInactive ? stats.positionOffersInactive : 0 }}
                  </span>
                </v-chip>
                Inactivas
              </v-chip>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4" class="py-0 px-1 mt-2 mt-md-0">
          <v-card elevation="3" class="d-flex flex-column" style="width: 100%">
            <v-card-actions class="pa-1 justify-center">
              Postulaciones por estatus
            </v-card-actions>
            <v-divider />
            <v-row no-gutters justify="center">
              <v-chip
                :color="statusOptions.find((status) => status.id === 0).color"
                text-color="white"
              >
                <v-chip small class="grey darken-4" text-color="white">
                  <v-progress-circular
                    width="2"
                    indeterminate
                    size="15"
                    v-if="loadingStats"
                  />
                  <span v-else>
                    {{ stats.jobApplications0 ? stats.jobApplications0 : 0 }}
                  </span>
                </v-chip>
                Recibidos
              </v-chip>
              <v-chip
                :color="statusOptions.find((status) => status.id === 1).color"
                text-color="white"
              >
                <v-chip small class="grey darken-4" text-color="white">
                  <v-progress-circular
                    width="2"
                    indeterminate
                    size="15"
                    v-if="loadingStats"
                  />
                  <span v-else>
                    {{ stats.jobApplications1 ? stats.jobApplications1 : 0 }}
                  </span>
                </v-chip>
                Admitidas
              </v-chip>
              <v-chip
                :color="statusOptions.find((status) => status.id === 2).color"
                text-color="white"
              >
                <v-chip small class="grey darken-4" text-color="white">
                  <v-progress-circular
                    width="2"
                    indeterminate
                    size="15"
                    v-if="loadingStats"
                  />
                  <span v-else>
                    {{ stats.jobApplications2 ? stats.jobApplications2 : 0 }}
                  </span>
                </v-chip>
                Rechazados
              </v-chip>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4" class="py-0 px-1 mt-2 mt-md-0">
          <v-card elevation="3" class="d-flex flex-column" style="width: 100%">
            <v-card-actions class="pa-1 justify-center">
              Entrevistas por estatus
            </v-card-actions>
            <v-divider />
            <v-row no-gutters justify="center">
              <v-chip
                :color="statusOptions.find((status) => status.id === 0).color"
                text-color="white"
              >
                <v-chip small class="grey darken-4" text-color="white">
                  <v-progress-circular
                    width="2"
                    indeterminate
                    size="15"
                    v-if="loadingStats"
                  />
                  <span v-else>
                    {{ stats.interviews0 ? stats.interviews0 : 0 }}
                  </span>
                </v-chip>
                Pendientes
              </v-chip>
              <v-chip
                :color="statusOptions.find((status) => status.id === 1).color"
                text-color="white"
              >
                <v-chip small class="grey darken-4" text-color="white">
                  <v-progress-circular
                    width="2"
                    indeterminate
                    size="15"
                    v-if="loadingStats"
                  />
                  <span v-else>
                    {{ stats.interviews1 ? stats.interviews1 : 0 }}
                  </span>
                </v-chip>
                Completados
              </v-chip>
              <v-chip
                :color="statusOptions.find((status) => status.id === 2).color"
                text-color="white"
              >
                <v-chip small class="grey darken-4" text-color="white">
                  <v-progress-circular
                    width="2"
                    indeterminate
                    size="15"
                    v-if="loadingStats"
                  />
                  <span v-else>
                    {{ stats.interviews2 ? stats.interviews2 : 0 }}
                  </span>
                </v-chip>
                Cancelados
              </v-chip>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="mx-4 mt-10">
        <v-tabs v-model="tab" centered slider-color="teal" class="ma-0 pa-0">
          <v-tab href="#tab1"> Oferta laboral </v-tab>
          <v-tab href="#tab2"> Cronograma de entrevistas </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item value="tab1">
            <v-data-table
              class="py-3"
              :key="dataTableIndex"
              :headers="headers"
              :items="offers"
              locale="es-VE"
              :hide-default-footer="true"
              :items-per-page="itemsPerPage ? itemsPerPage : 20"
              :loading="loadingPositionOffers"
            >
              <template v-slot:top>
                <v-row justify="end" class="mx-2 mb-2 pt-2">
                  <v-btn small color="primary" @click="handleCreate()" class="ml-2"
                    >Agregar</v-btn
                  >
                </v-row>
              </template>

              <template v-slot:[`item.active`]="{ item }">
                <v-icon>{{ item.active ? 'mdi-check' : 'mdi-close' }}</v-icon>
              </template>
              <template v-slot:[`item.openApplications`]="{ item }">
                {{ item.job_applications.length }}
              </template>
              <template v-slot:[`item.job_applications`]="{ item }">
                {{ item.job_applications.length }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <div class="d-flex justify-space-between">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-on="on"
                        v-bind="attrs"
                        class="mr-1"
                        @click="
                          $router.push({
                            name: 'administrative/human-talent/PositionApplications',
                            params: { positionOfferId: item.id },
                          })
                        "
                      >
                        mdi-history
                      </v-icon>
                    </template>
                    <span>Ver Postulaciones</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" @click="handleEdit(item)">
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" @click="handleDelete(item)">
                        mdi-delete
                      </v-icon>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip>
                </div>
              </template>
              <template v-slot:no-data
                ><div class="text-center" />
                {{ 'No hay registros' }}
              </template>
              <template v-slot:no-results
                ><div class="text-center" />
                {{ 'No hay resultados para su búsqueda' }}
              </template>
            </v-data-table>
            <div class="text-center py-2" style="margin: 10px 0px">
              <v-pagination
                @input="pagination($event)"
                color="primary"
                v-model="page"
                :length="pages"
              />
            </div>
          </v-tab-item>
          <v-tab-item value="tab2">
            <calendar-interviews />
          </v-tab-item>
        </v-tabs-items>
      </v-row>
    </v-card>
    <modal name="crudModal" :width="dialogWidth" :adaptive="true">
      <v-card elevation="0" class="ma-4">
        <h2 class="text-center">
          {{ flow === 'create' ? 'Crear oferta laboral' : 'Editar oferta laboral' }}
        </h2>

        <v-card-text>
          <span v-if="flow === 'edit' && item.created_at"
            >Fecha de creación: {{ toLocaleDateString(createdAt, 'es-VE') }}.
            {{ dateRange > 0 ? 'Hace ' + dateRange + ' dias' : '' }}</span
          >
          <v-form ref="formOffers" v-model="valid" class="mx-0 mt-2">
            <v-row class="mx-0 align-center">
              <v-col cols="12" sm="6" md="4" class="pt-0">
                <span class="caption">Cargo</span>
                <multiselect
                  v-model="selectedPosition"
                  label="name"
                  track-by="id"
                  placeholder="Seleccionar Cargo"
                  :options="positions"
                  :allow-empty="false"
                  deselect-label="Remover"
                  select-label="Seleccionar"
                  selected-label="Seleccionado"
                  :disabled="flow === 'edit' ? true : false"
                  :searchable="true"
                  :loading="loadingPositions"
                  :internal-search="false"
                  :clear-on-select="true"
                  :close-on-select="true"
                  :max-height="600"
                  :show-no-results="false"
                  :hide-selected="true"
                  @search-change="getPositions"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" class="pt-0">
                <span class="caption">Modalidad</span>
                <multiselect
                  :options="modalityOptions"
                  v-model="selectedModality"
                  label="value"
                  track-by="id"
                  placeholder="Seleccionar Modalidad"
                  :allow-empty="false"
                  :disabled="
                    item.job_applications && item.job_applications.length > 0
                      ? true
                      : false
                  "
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" class="pt-0">
                <span class="caption">Tiempo</span>
                <multiselect
                  :options="timeOptions"
                  v-model="selectedTime"
                  label="value"
                  track-by="id"
                  placeholder="Seleccionar Tiempo"
                  :allow-empty="false"
                  :disabled="
                    item.job_applications && item.job_applications.length > 0
                      ? true
                      : false
                  "
                />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  type="number"
                  label="Vacantes"
                  v-model="vacancies"
                  :rules="[rules.required]"
                  step="1"
                  min="1"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="Salario estimado"
                  v-model="estimatedSalary"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-row justify="center" align="center">
                  <v-img
                    max-width="200"
                    v-if="item.uploads && item.uploads.length > 0"
                    :src="item.uploads[0].route"
                    :alt="item.uploads[0].name"
                    class="col-12 col-sm-6"
                  />
                  <v-file-input
                    v-model="cover"
                    chips
                    :rules="[rules.fileSize]"
                    ref="file"
                    show-size
                    accept="image/png, image/jpeg, image/jpg"
                    :label="'Seleccione una imagen de portada'"
                  />
                </v-row>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-checkbox :label="active ? 'Activo' : 'Inactivo'" v-model="active" />
              </v-col>
              <v-col cols="12">
                <vue-editor
                  :placeholder="'Descripción de la oferta'"
                  :editorToolbar="editorToolbar"
                  v-model="description"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="row">
          <v-btn
            class="mx-auto"
            :disabled="!valid"
            color="primary"
            @click="handleSaveItem()"
          >
            {{ flow === 'create' ? 'Crear' : 'Guardar' }}
          </v-btn>

          <v-btn class="mx-auto" color="primary" @click="reset"> Limpiar </v-btn>
          <v-btn class="mx-auto" color="primary" @click="$modal.hide('crudModal')">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </modal>

    <!-- QR DIALOG -->
    <v-dialog v-model="showQR" :width="dialogWidth" max-width="500" overlay-opacity="0.8">
      <v-card class="pa-2">
        <picture class="d-flex justify-center">
          <qr-code
            id="qrcode"
            :text="qrText"
            :correctLevel="3"
            :margin="10"
            colorDark="#003a56"
            :logoSrc="qrLogo"
            :logoScale="0.2"
            :logoMargin="0"
            :logoCornerRadius="20"
            :size="800"
            :callback="qrCallback"
            style="max-width: 90%"
          />
        </picture>
        <span class="d-block text-center">
          <a
            :href="qrText"
            target="_blank"
            rel="noopener"
            class="caption text-decoration-none"
            >{{ qrText }}</a
          >
        </span>
        <v-card-actions class="d-flex flex-wrap justify-center">
          <v-btn class="ma-1" small outlined @click.prevent="downloadImg()"
            >Descargar</v-btn
          >
          <v-btn class="ma-1" small outlined @click="qrIframe()">
            {{ qrIframeBtnText }} <v-icon small class="ml-2">mdi-content-copy</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- QR DIALOG -->
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { decryptData, base64Encode } from '@/utils/encryption'
  import { toLocaleDateString } from '@/utils/helpers'
  import { VueEditor } from 'vue2-editor'
  import CalendarInterviews from '@/components/HumanTalent/CalendarInterviews.vue'

  export default {
    name: 'JobBank',
    components: { CalendarInterviews, VueEditor },
    data: function () {
      return {
        config: {},
        showQR: false,
        qrCallback: null,
        qrLogo: '/static/default/logo-gris--nube-QR.png',
        qrIframeBtnText: 'Incrustar Iframe',
        active: false,
        index: 0,
        loadingStats: false,
        loadingPositions: false,
        loadingPositionOffers: false,
        tab: null,
        types: [],
        selectedType: null,
        loadingTypes: false,
        valid: false,
        dateRange: '',
        createdAt: '',
        toDay: new Date(),
        flow: '',
        item: {},
        vacancies: null,
        description: '',
        estimatedSalary: null,
        selectedPosition: null,
        selectedTime: null,
        selectedModality: null,
        offers: [],
        cover: null,
        expiredAt: null,
        dataTableIndex: 1,
        itemsPerPage: 10,
        page: 1,
        pages: 1,
        dialog: false,
        positions: [],
        headers: [
          {
            text: 'Cargo',
            align: 'start',
            value: 'position.name',
          },
          { text: 'Vacantes', value: 'vacancies', align: 'end' },
          { text: 'Postulaciones Recibidas', value: 'openApplications', align: 'end' },
          { text: 'Postulaciones Totales', value: 'job_applications', align: 'end' },
          { text: 'Activa', value: 'active', align: 'center', sortable: false },
          {
            text: 'Acciones',
            value: 'actions',
            align: 'center',
            width: '100px',
          },
        ],
        editorToolbar: [
          [{ header: [false, 1, 2, 3, 4, 5, 6] }],
          ['bold', 'italic', 'underline', 'strike'], // toggled buttons
          [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
          ['blockquote', 'code-block'],
          [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
          [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          ['link', 'video'],
          ['clean'], // remove formatting button
        ],
        loading: false,
        statusOptions: [
          {
            id: 0,
            name: 'Recibidas',
            color: 'amber darken-2',
          },
          {
            id: 1,
            name: 'Admitidas',
            color: 'green darken-2',
          },
          {
            id: 2,
            name: 'Rechazadas',
            color: 'red darken-2',
          },
        ],
        modalityOptions: [
          { id: 0, value: 'Remoto' },
          { id: 1, value: 'Presencial' },
        ],

        timeOptions: [
          { id: 0, value: 'Tiempo Completo' },
          { id: 1, value: 'Tiempo Parcial' },
          { id: 2, value: 'Guardia 7x3' },
        ],
        stats: {},
        priorityStats: {},
        attrs: {
          class: 'mb-6',
          boilerplate: true,
          elevation: 2,
        },
        navigationElements: [
          {
            text: 'Talento Humano',
            disabled: false,
            href: '/business/supervision-control/admin-panel',
            name: 'administrative/human-talent/AdminPanel',
          },
          {
            text: 'Bolsa de empleo',
            disabled: true,
            href: '',
          },
        ],
        orderBy: { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
        orderOptions: [
          { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
          { id: 2, name: 'Antiguos', order: 'created_at', by: 'asc' },
          { id: 3, name: 'Nombre', order: 'name', by: 'asc' },
          { id: 4, name: 'Última Edición', order: 'updated_at', by: 'desc' },
        ],
        rules: {
          required: (v) => !!v || 'Requerido.',
          max: (v) =>
            (v ? v.length <= 250 : true) || 'Debe poseer menos de 250 caracteres',
          minValue: (v) => (v ? v >= 1 : true) || 'El valor debe ser mayor que 0',
          fileSize: (v) =>
            (v ? v.size < 5120000 : true) ||
            'El peso del archivo debe ser inferior a 5MB',
        },
      }
    },
    created() {
      this.getStats()
      this.getPositions()
      this.getOffers()
    },
    watch: {
      selectedType() {
        this.getPositions()
      },
    },
    filters: {
      interviewDateTimeFormat(datetime) {
        let locales = ''
        navigator.languages && navigator.languages.length
          ? (locales = navigator.languages[0])
          : (locales = navigator.language)
        return new Date(datetime).toLocaleTimeString(locales, {
          weekday: 'long',
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          timeZone: localStorage.getItem('timeZone'),
          timeZoneName: 'long',
        })
      },
    },
    computed: {
      ...mapGetters(['locale', 'timeZone', 'userCurrentCompany']),
      company: {
        get() {
          return decryptData(this.userCurrentCompany)
        },
        set(newValue) {},
      },
      companies() {
        if (
          this.$session.get('companies') &&
          decryptData(this.$session.get('companies'))
        ) {
          return decryptData(this.$session.get('companies'))
        } else {
          return []
        }
      },
      userId() {
        if (this.$session.get('tokenSession')) {
          return decryptData(this.$session.get('userId'))
        } else {
          return false
        }
      },
      dialogWidth() {
        return this.$vuetify.breakpoint.smAndDown ? '95%' : '80%'
      },
      qrText() {
        if (this.company.id) {
          return (
            process.env.VUE_APP_WEBSITE + 'embed/jobs/' + base64Encode(this.company.id)
          )
        } else {
          return process.env.VUE_APP_WEBSITE + 'embed/jobs/'
        }
      },
    },
    methods: {
      toLocaleDateString: toLocaleDateString,

      timelineDateString(date, locales) {
        try {
          return new Date(date).toLocaleDateString(locales, {
            day: 'numeric',
            month: 'long',
          })
        } catch (error) {
          console.warn(error)
          return 'Invalid data'
        }
      },
      handleExpirationData(date, message) {
        const expiredDate = new Date(date)
        if (this.toDay.getTime() > expiredDate.getTime()) {
          return message
        } else {
          return date.split('-').reverse().join('-')
        }
      },
      qrIframe() {
        const textToCopy = `
              <iframe width="100%" height="700" src="http://localhost:8080/embed/jobs/1" frameborder="0"  allowfullscreen>
              </iframe>;
          `
        navigator.clipboard.writeText(textToCopy)
        // .then(
        //   function () {
        //     /* clipboard successfully set */
        //     return window.alert(textToCopy)
        //   },
        //   function () {
        //     /* clipboard write failed */
        //     return window.alert('Oops!, ha ocurrido un error al acceder al portapapeles')
        //   }
        // )
        this.qrIframeBtnText = 'copiado'
      },
      async pagination(page) {
        this.page = page
        await this.getOffers()
      },

      reset() {
        this.$refs.formOffers.reset()
        this.$refs.formOffers.resetValidation()
      },

      fullName({ name, lastname }) {
        if (!name) return ''
        return `${name} ${lastname}`
      },

      getTypes() {
        this.loadingTypes = true
        this.$axios.get('types?limit=20').then((response) => {
          this.types = this.types.concat(response.data)
          this.selectedType = response.data.find((x) => x.id === this.company.type_id)
          this.typeId = this.selectedType.id

          this.loadingTypes = false
        })
      },

      async getPositions(search) {
        this.loadingPositions = true
        let searchStr = ''
        if (search && search !== '') {
          searchStr = '&search=' + search
        }
        this.$axios
          .get('positions?limit=100&type_id=' + this.company.type_id + searchStr)
          .then((response) => {
            this.positions = response.data
            this.loadingPositions = false
          })
      },

      async getOffers() {
        this.loadingPositionOffers = true
        const withString = '&with[]=position&with[]=jobApplications&with[]=uploads'
        const queryParams =
          '&order=created_at&by=asc&page=' +
          this.page +
          '&company_id=' +
          this.company.id +
          withString
        this.$axios.get('position-offers?' + queryParams).then((response) => {
          this.offers = response.data.data
          this.totalItems = response.data.total
          this.itemsPerPage = response.data.per_page
          if (response.data.from) {
            this.page = response.data.current_page
            this.pages = response.data.last_page
          } else {
            this.page = 1
            this.pages = 1
          }
          this.loadingPositionOffers = false
        })
      },

      handleCreate() {
        this.flow = 'create'
        this.item = {}
        this.active = false
        this.vacancies = null
        this.description = null
        this.estimatedSalary = null
        this.selectedPosition = null
        this.selectedModality = this.modalityOptions[0]
        this.selectedTime = this.timeOptions[0]
        this.cover = null
        this.$modal.show('crudModal')
      },
      handleEdit(item) {
        this.flow = 'edit'
        this.item = item
        this.vacancies = item.vacancies
        this.description = item.description
        this.estimatedSalary = item.estimated_salary
        this.selectedPosition = this.positions.find((p) => p.id === item.position.id)
        this.selectedModality = this.modalityOptions.find((m) => m.id === item.modality)
        this.selectedTime = this.timeOptions.find((t) => t.id === item.time)
        this.cover = item.uploads.find((cover) => cover.fileType === 'cover')
        this.active = item.active
        this.createdAt = new Date(item.created_at)
        this.dateRange = Math.ceil(
          Math.abs(this.createdAt - this.toDay) / (1000 * 60 * 60 * 24)
        )
        this.$modal.show('crudModal')
      },
      async handleSaveItem() {
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest',
        }
        const formData = new FormData()

        if (!this.selectedPosition) {
          this.$swal({
            title: '',
            text: 'Seleccione un cargo válido',
            icon: 'warning',
          })
        } else {
          formData.append('active', +this.active)
          formData.append('position_id', this.selectedPosition.id)
          formData.append('company_id', this.company.id)
          formData.append('description', this.description)
          formData.append('time', this.selectedTime.id)
          formData.append('vacancies', this.vacancies)
          formData.append('estimated_salary', this.estimatedSalary)
          formData.append('modality', this.selectedModality.id)
          formData.append('file', this.cover)
          formData.append('fileType', 'cover')

          if (this.flow === 'create') {
            this.$axios.post('position-offers', formData, this.config).then(() => {
              this.page = 1
              this.getOffers()
              this.getStats()
              this.$modal.hide('crudModal')
            })
          } else {
            formData.append('_method', 'patch')
            this.$axios
              .patch('position-offers/' + this.item.id, formData, this.config)
              .then(() => {
                this.page = 1
                this.getOffers()
                this.getStats()
                this.$modal.hide('crudModal')
              })
          }
        }
      },
      handleDelete(item) {
        this.$swal({
          title: '¿Estás seguro?',
          text: 'Eliminarás esta información de forma permanente',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Si, eliminar',
        }).then((result) => {
          if (result.isConfirmed) {
            this.$swal({
              title: '¡Listo!',
              text: 'El registro fue eliminado',
              icon: 'success',
            })
            this.deleteItem(item)
          }
        })
      },
      deleteItem(item) {
        this.$axios.delete('position-offers/' + item.id).then(() => {
          this.page = 1
          this.offers = []
          this.getOffers()
        })
      },
      getStats() {
        this.loadingStats = true
        this.$axios
          .get('stats-management/job-bank-stats?company_id=' + this.company.id)
          .then((response) => {
            this.stats = response.data
            this.loadingStats = false
          })
      },
      downloadImg() {
        const qrCode = document.querySelector('#qrcode')
        const url = qrCode.src
        const a = document.createElement('a')
        const event = new MouseEvent('click')
        a.download = this.company.name + '_Job_bank'
        a.href = url
        a.dispatchEvent(event)
      },
    },
  }
</script>
<style lang="scss">
  @import '@/sass/cruds/_crudStyles.scss';
  .job-bank {
    .v-chip {
      padding: 0 8px;
      margin: 4px;
      .v-chip__content {
        font-size: 12px;
      }
    }
    .stats {
      z-index: 0;
      .v-chip {
        margin: 4px;
        padding: 0 8px 0 4px;
        .v-chip {
          padding: 0 8px;
          margin: 0 4px 0 0;
        }
      }
    }
    .v-input--radio-group__input {
      flex-direction: row;
      flex-wrap: wrap;
    }
    .v-tabs-slider {
      margin-top: -5px;
    }
    .v-skeleton-loader {
      .v-skeleton-loader__chip {
        height: 24px;
        width: 55px;
      }
      .v-skeleton-loader__avatar {
        height: 25px;
        width: 25px;
        margin: 4px;
      }
      .skeleton__avatar--x-mall > .v-skeleton-loader__avatar {
        width: 15px;
        height: 15px;
      }
    }
    .theme--light.v-tabs-items {
      width: 100%;
      background-color: transparent;
    }
  }
</style>
