<template>
  <v-sheet class="pb-2 calendar-interviews" color="transparent">
    <v-row justify="space-between" align="center" no-gutters class="mt-1">
      <v-col cols="12" sm="4">
        <v-select
          v-model="statusInterview"
          :items="statusInterviews"
          dense
          item-text="name"
          item-value="id"
          outlined
          hide-details
          label="Estatus"
          class="ma-2"
          @change="calendarIndex++"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-select
          v-model="calendarType"
          :items="calendarTypes"
          dense
          outlined
          hide-details
          class="ma-2"
          label="Periodo"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <div class="d-flex align-center justify-end">
          <v-btn icon class="ma-1" @click="$refs.calendar.prev()">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-btn icon class="ma-1" @click="$refs.calendar.next()">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-sheet height="600" class="pb-2" color="transparent">
      <v-calendar
        :key="calendarIndex"
        ref="calendar"
        v-model="value"
        :weekdays="weekday"
        :type="calendarType"
        :events="events"
        :event-overlap-mode="mode"
        :event-overlap-threshold="30"
        :event-color="getEventColor"
        show-month-on-first
        @change="updateRange"
        :locale="locale"
        @click:event="showEvent"
        @click:more="viewDay"
        @click:date="viewDay"
      />
      <v-menu
        v-model="selectedOpen"
        :close-on-content-click="false"
        :activator="selectedElement"
        offset-x
        max-width="500"
      >
        <v-card :elevation="0" max-width="600px">
          <v-toolbar
            :color="
              selectedEvent && selectedEvent.color ? selectedEvent.color : 'primary'
            "
            dark
          >
            <v-toolbar-title
              v-html="
                selectedEvent.name +
                '<br><small>Postulación #' +
                selectedEvent.job_application_id +
                '</small>'
              "
            />
            <v-spacer />
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" @click="selectedOpen = false"
                  >mdi-close</v-icon
                >
              </template>
              <span>Ocultar detalles</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text v-if="selectedEvent.datetime">
            <p>
              Tiempo de inicio:
              {{ selectedEvent.datetime | interviewDateTimeFormat }}
            </p>
            <p>
              Moderador:
              {{ `${selectedEvent.user.name} ${selectedEvent.user.lastname}` }}
            </p>
            <p>
              Aspirante:
              {{
                `${selectedEvent.job_application.name} ${selectedEvent.job_application.lastname}`
              }}
            </p>
            <p v-if="selectedEvent.observation">
              Observación:
              {{ selectedEvent.observation }}
            </p>
            <p v-if="selectedEvent.conclusion">
              Coclusión:
              {{ selectedEvent.conclusion }}
            </p>
            <p>
              Modalidad:
              {{ selectedEvent.type | toTypeFormat }}
            </p>
            <p v-if="selectedEvent.url && selectedEvent.type === 0">
              Enlace:
              {{ selectedEvent.url }}
            </p>
            <p v-if="selectedEvent.details">
              Detalles:
              {{ selectedEvent.details }}
            </p>
          </v-card-text>
        </v-card>
      </v-menu>
    </v-sheet>
  </v-sheet>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { decryptData } from '@/utils/encryption'
  export default {
    data() {
      return {
        statusInterview: 0,
        calendarType: 'month',
        calendarTypes: ['month', 'week', 'day', '4day'],
        mode: 'stack',
        modes: ['stack', 'column'],
        weekday: [1, 2, 3, 4, 5, 6, 0],
        interviews: [],
        events: [],
        focus: '',
        selectedEvent: {},
        value: '',
        selectedElement: null,
        selectedOpen: false,
        calendarIndex: 0,
        statusInterviews: [
          {
            id: 0,
            name: 'Pendientes',
            color: 'amber darken-2',
          },
          {
            id: 1,
            name: 'Completadas',
            color: 'green darken-2',
          },
          {
            id: 2,
            name: 'Canceladas',
            color: 'red darken-2',
          },
        ],
      }
    },
    filters: {
      interviewDateTimeFormat(datetime) {
        let locales = ''
        const date = new Date(datetime)
        const miliseconds = date.getTime()
        const minutesOffset = date.getTimezoneOffset()
        navigator.languages && navigator.languages.length
          ? (locales = navigator.languages[0])
          : (locales = navigator.language)

        return new Date(miliseconds - minutesOffset * 60 * 1000).toLocaleTimeString(
          locales,
          {
            weekday: 'long',
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            timeZone: localStorage.getItem('timeZone'),
            timeZoneName: 'long',
          }
        )
      },
      toTypeFormat(type) {
        let typeText = ''
        if (type !== null && type !== undefined) {
          type === 0 ? (typeText = 'Online') : (typeText = 'Presencial')
        }
        return typeText
      },
    },
    computed: {
      ...mapGetters(['locale', 'timeZone', 'userCurrentCompany']),
      company: {
        get() {
          return decryptData(this.userCurrentCompany)
        },
        set(newValue) {},
      },
    },
    created() {},
    methods: {
      viewDay({ date }) {
        this.focus = date
        this.calendarType = 'day'
      },

      getEventColor(event) {
        return event.color
      },

      updateRange({ start, end }) {
        this.interviews = []
        const withString = '&with[]=jobApplication.positionOffer.position&with[]=user'
        const queryParams =
          '?limit=100&startAt=' +
          start.date +
          '&endAt=' +
          end.date +
          '&status=' +
          this.statusInterview +
          '&company_id=' +
          this.company.id +
          withString
        this.$axios.get('interviews' + queryParams).then((response) => {
          this.interviews = response.data
          this.interviews.forEach((interview) => {
            interview.name = interview.job_application.position_offer.position.name
            interview.color = this.statusInterviews.find(
              (status) => status.id === interview.status
            ).color
            const date = new Date(interview.datetime)
            const miliseconds = date.getTime()
            const minutesOffset = date.getTimezoneOffset()
            interview.start = new Date(miliseconds - minutesOffset * 60 * 1000)
            interview.end = new Date(miliseconds - (minutesOffset - 60) * 60 * 1000)
          })

          this.events = this.interviews
        })
      },

      showEvent({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          requestAnimationFrame(() =>
            requestAnimationFrame(() => (this.selectedOpen = true))
          )
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          requestAnimationFrame(() => requestAnimationFrame(() => open()))
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },
    },
  }
</script>
<style lang="scss">
  .calendar-interviews {
    .v-calendar .v-event {
      font-size: 10px;
      margin: 1px 2px;
      width: calc(100% - 3px) !important;
    }
    .v-present {
      .theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        background-color: #cdcdcd;
      }
    }
  }
</style>
